<template>
    <div class="o-card">
        <b-card no-body bg-variant="white" border-variant="dark" header-border-variant="dark">
            <template #header>
                <h4 class="mb-0">
                    {{ item.Title }}
                </h4>
            </template>
            <b-card-body>
                <b-card-text v-for="t in splitLines(item.Description)" :key="t.ID">
                    {{ t }}
                </b-card-text>
                
                <b-card-text v-if="item.Director">
                    Руководитель Оркестра: {{ item.Director }}
                </b-card-text>

                <b-card-text v-if="item.Phone">
                    Tel: {{ item.Phone }}
                </b-card-text>

                <b-card-text v-if="item.Email">
                    Email: {{ item.Email }}
                </b-card-text>

                <b-card-text v-if="item.Website">
                    Website: <a :href="item.Website">{{ item.Website }}</a>
                </b-card-text>
                <b-btn v-if="item.AlbumID" block variant="primary" :to="{path: 'photos/album/' + item.AlbumID}">
                    Фото
                </b-btn>
            </b-card-body>
        </b-card>
    </div>
</template>
<script>

export default {
    name: "OrchestrasCard",
    props: {
        item: {
            type: Object
        }
    },
    methods: {

        // Split text by newlines
        splitLines (t) {
            if (!t) {
                return '';
            }
            return t.split(/\r\n|\r|\n/);
        }

    }
};
</script>
<style scoped>
    .o-card {
        padding-bottom: 10px;
    }
</style>
